<template>
  <div class="epidemicRegister">
    <div class="epidemicRegister-t">
      <div class="title">
        <div class="title-l" v-if="formsType == 1">
          明珠社区居民健康自查信息登记
        </div>
        <div class="title-r" v-else>明珠社区宁波市外来（返）人员信息登记</div>
      </div>
      <div class="epidemicRegister-tb">
        <div class="epidemicRegister-tb-l" v-if="formsType == 1">
          当前，全市新型冠状病毒肺炎疫情防控工作进入关键阶段，为进一步深化疫情防控“4个1”排查工作，及时了解跟进居民健康信息情况，运用大数据等新技术实现科学防治、精准施策，为打赢疫情防控狙击战提供大数据支撑。
        </div>
        <div class="epidemicRegister-tb-r" v-else>
          所有宁波市外来（返）明珠社区人员：
          当前疫情防控形势严峻、根据省市防疫相关要求，明珠社区启用宁波市外来（返）乡人员信息登记系统。
          “线上”自助登机信息能加快疫情排查速度，便于相关部门及时掌握群众的流向及身体状况，分地区、分情况、有针对性地加强防范，提升疫情防控工作效率；同时也避免不必要的人员交叉感染，防止疫情扩散。
          即日起，请所有宁波市外来（返）明珠社区的人员，按照防疫常态化要求主动上报登记，务必如实填写，对于刻意隐瞒真实情况或提供虚假信息的，将承担相应的法律责任。
        </div>
      </div>
    </div>
    <div class="epidemicRegister-b">
      <div class="main">
        <div class="item" v-for="(item, index) in form" :key="index">
          <div class="oItem" v-if="item.problemType == 2">
            <div class="item-l">{{ item.problemName }}</div>
            <div class="item-r">
              <v-input
                v-model="item.problemValue"
                :maxlength="10000"
                placeholder="请填写"
              ></v-input>
            </div>
          </div>
          <div
            class="oItem"
            @click="select(index)"
            v-if="item.problemType == 3"
          >
            <div class="item-l">{{ item.problemName }}</div>
            <div class="item-r">{{ item.problemValue }}</div>
            <div class="arrow"><img src="@/assets/img/tip.png" alt="" /></div>
          </div>
          <div class="oItem" v-if="item.problemType == 1">
            <div class="item-l">{{ item.problemName }}</div>
            <div class="item-r">
              <v-radio-group
                :radioObj="item.radioObj"
                :value.sync="item.problemValue"
              ></v-radio-group>
            </div>
          </div>
        </div>
        <div class="btn" @click="toSubmit">提交</div>
      </div>
    </div>
    <v-dateTimePicker
      :value="applyEndDate | dateFormat"
      :valueShow.sync="showEnd"
      :isAuto="true"
      @confirmPicker="confirmEnd"
    ></v-dateTimePicker>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getSceneParamUrl, getFormURL, formsSubmitURL } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "epidemicRegister",
  components: {},
  data() {
    return {
      radioObj: [
        { name: "1", value: "通过" },
        { name: "2", value: "拒绝" },
      ],
      showEnd: false,
      applyEndDate: "",
      formsType: "",
      params: {},
      form: [],
      paramId: "",
      formsId: "",
      oIndex: "",
      forData: {},
    };
  },
  filters: {
    dateFormat: function (value) {
      if (value) {
        return moment(value).toDate();
      }
      return new Date();
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.formsType = this.$route.query.formsType;
    this.paramId = getHashParam("paramId");
  },
  async mounted() {
    try {
      if (this.paramId) {
        await this.getSceneParam();
      }
      await this.getDetail();
      await toRegister(this.$route.path, this.$route.query, "防疫表单登记");
    } catch (error) {}
    // gloabalCount("", 21, 1);
  },
  methods: {
    toSubmit() {
      for (var i = 0; i <= this.form.length - 1; i++) {
        if (this.form[i].ifRequired == 1) {
          if (this.form[i].problemType == 3) {
            if (
              !this.form[i].problemValue ||
              this.form[i].problemValue == "请选择日期"
            ) {
              this.$toast({
                message: `请选择${this.form[i].problemName}`,
                duration: 2000,
              });
              return;
            }
          } else {
            if (!this.form[i].problemValue) {
              this.$toast({
                message: `请填写${this.form[i].problemName}`,
                duration: 2000,
              });
              return;
            }
          }
        }
      }
      this.postData();
    },
    async postData() {
      let params = {
        formsId: this.formData.formsId,
        userId: this.userId,
        contentRequests: this.form,
      };
      let res = await this.$axios.post(`${formsSubmitURL}`, params, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.code == 200) {
        this.$router.replace({
          name: "epidemicRes",
        });
      } else {
      }
    },
    select(index) {
      this.showEnd = true;
      this.oIndex = index;
    },
    confirmEnd(val) {
      console.log(val, "+++");
      this.form[this.oIndex].problemValue = moment(val).format("YYYY-MM-DD");
    },
    async getDetail() {
      let params = {
        formsType: this.formsType,
      };
      if (this.formsId) {
        params.formsType = this.formsId;
      }
      let res = await this.$axios.get(getFormURL, {
        params,
      });
      if (res.code === 200 && res.data) {
        this.formData = res.data;
        this.form = res.data.detailsVOS;
        this.form.forEach((ele) => {
          if (ele.problemType == 3) {
            ele.problemValue = "请选择日期";
          } else {
            ele.problemValue = "";
          }
          if (ele.problemType == 1) {
            ele.radioObj = [];
            let arr;
            if (ele.optionValue) {
              arr = ele.optionValue.split(",");
            }
            ele.problemValue = arr[0];
            for (var i = 0; i <= arr.length - 1; i++) {
              let obj = {};
              obj.name = arr[i];
              obj.value = arr[i];
              ele.radioObj.push(obj);
            }
          }
        });
        console.log(this.form, "==");
      }
    },
    async getSceneParam() {
      let paramId = getHashParam("paramId");
      const res = await this.$axios.get(getSceneParamUrl, {
        params: {
          paramId,
        },
      });
      if (res.code === 200 && res.success) {
        this.params = res.data ? JSON.parse(res.data) : {};
        this.formsId = this.params.formsType;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.epidemicRegister {
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
  min-height: 100vh;
  background: #fafafa;
  padding: 0 30px 30px;
  ::v-deep .van-pull-refresh {
    min-height: 100vh;
  }
  .title {
    padding: 36px 0 20px;
    .title-l,
    .title-r {
      font-size: 30px;
      font-weight: 600;
      color: #333333;
      line-height: 42px;
    }
  }
  .epidemicRegister-tb {
    padding: 18px 30px 14px;
    background: rgba(252, 255, 250, 0.8);
    font-size: 24px;
    font-weight: 400;
    color: #1e4112;
    line-height: 34px;
    .epidemicRegister-tb-l,
    .epidemicRegister-tb-r {
      color: #1e4112;
    }
  }
  .epidemicRegister-b {
    margin-top: 18px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
    border-radius: 16px;
    padding: 0 30px 30px;
    .oItem {
      padding: 24px 20px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .arrow {
        position: absolute;
        width: 28px;
        height: 28px;
        right: -2px;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-l {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        width: 200px;
      }
      .item-r {
        flex: 1;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        text-align: right;
        /deep/ .van-cell {
          padding: 0;
          .van-field__control {
            text-align: right;
          }
        }
        /deep/ .van-radio-group--horizontal {
          justify-content: right;
        }
      }
    }
    .btn {
      width: 550px;
      height: 66px;
      background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
      border-radius: 10px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      text-align: center;
      margin: 38px auto;
    }
  }
}
</style>
